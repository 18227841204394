export default {
  type: "cash-register-readings",
  total_amount: null,
  starts_at: null,
  number_z: null,
  ends_at: null,
  created_by: null,
  updated_by: null,
  relationshipNames: [
    "organization",
    "establishment",
    "serviceCenter",
    "cafeteria",
    "cashRegister"
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  establishment: {
    type: "establishments",
    id: null,
  },
  serviceCenter: {
    type: "service-centers",
    id: null,
  },
  cafeteria: {
    type: "cafeterias",
    id: null,
  },
  cashRegister: {
    type: "cash-registers",
    id: null,
  },
  allowedUsers: [],
};
